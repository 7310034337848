import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaPen, FaTimes } from "react-icons/fa";
import BatchManagementServices from "../../services/BatchManagementServices";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

export default function ManageHolidayList() {
  const navigate = useNavigate();
  const [postData, setPostData] = useState({
    date: "",
    event: "",
    xlsx: null,
  });
  const [isExcel, setIsExcel] = useState(false);
  const [postDataError, setPostDataError] = useState({});
  const [updateId, setUpdateId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  var token = localStorage.getItem("token");

  useEffect(() => {
    getHolidays()
  }, []);

  const DateFormat = (dateValue) => {
    let date = new Date(dateValue);
    return `${date.getFullYear()}-${
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
  };

  const getHolidays = () => {
    if (token) {
      BatchManagementServices.getHolidays(token).then((res)=>{
          if(res.data.success){
              setHolidayList(res.data.data);
          }
      }).catch((error)=> console.log(error));
    }
    else{
        navigate("/login");
    }
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let file
    if(isExcel){
      file = e.target.files[0];
    }
    switch (name) {
      case "date":
          if (!value?.length) {
              setPostDataError({
                  ...postDataError,
                  [name]: "Please enter holiday date",
              });
              setPostData({ ...postData, [name]: value });
          } else {
              setPostData({ ...postData, [name]: value });
              setPostDataError({
                  ...postDataError,
                  [name]: "",
              });
          }
          break;
      case "event":
        if (!value?.length) {
            setPostDataError({
            ...postDataError,
            [name]: "Please enter event",
            });
            setPostData({ ...postData, [name]: value });
        } else {
            setPostData({ ...postData, [name]: value });
            setPostDataError({
                ...postDataError,
                [name]: "",
            });
        }
        break;
      case "xlsx":
        if (!file) {
            setPostDataError({
            ...postDataError,
            [name]: "Please choose xlsx file",
            });
            setPostData({ ...postData, [name]: file });
        } else {
            setPostData({ ...postData, [name]: file });
            setPostDataError({
                ...postDataError,
                [name]: "",
            });
        }
        break;
      default:
    }
  };

  const checkValid = (holidayData) => {
    const setError = {};
    let valid = true;
    const keys = Object.keys(holidayData);
    for (let holidayValue of keys) {
      const value = holidayData[holidayValue];
      if(isExcel && holidayValue === 'xlsx'){
        if (value === "" || value === undefined || value === null) {
          valid = false;
          setError[holidayValue] = "Please choose xlsx file";
        }
      }
      else if(!isExcel){
        if (value === "" || value === undefined) {
          valid = false;
          setError[holidayValue] = "Required Filed";
        }
      }
    }
    setPostDataError(setError);
    return valid;
  };

  let closeModal = () => {
    setOpenModal(!openModal);
    setUpdateId("");
  };

  const handleRemoveHoliday = () => {
    setOpenModal(false);
    if (token && updateId) {
      BatchManagementServices.removeHoliday(updateId, token)
        .then((res) => {
          setUpdateId("");
          alert(res.data.msg);
          getHolidays();
        })
        .catch((err) => {
          alert(err.response.data.msg)
        });
    } else {
      navigate("/login");
    }
  };

  const handleAddHoliday = (e) => {
    e.preventDefault();
    if (isExcel) {
      if (checkValid(postData)) {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        try{
          reader.onload = async (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary", bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = await XLSX.utils.sheet_to_json(ws);
            const holidayList = [];
            let isInsert = true;
            // Insert to db
            if (data.length > 0) {
              data.forEach(val => {
                if(val?.date && val?.event){
                  try {
                    if(new Date((val.date - 25569) * 86400000).toLocaleDateString("sv-SE") !== "Invalid Date"){
                      holidayList.push({
                        date: new Date((val.date - 25569) * 86400000).toLocaleDateString("sv-SE"), 
                        event: val.event
                      })
                    } else {
                      isInsert = false
                    }
                  } catch (error) {
                    isInsert = false
                  }
                }
                else isInsert=false;
              })
              if(isInsert && holidayList.length){
                let flag = true
                holidayList.map(holiday => {
                  if(holidayList.filter(element => element.date === holiday.date).length > 1){
                    flag = false;
                  }
                })
                flag ?
                BatchManagementServices.addHolidayList(holidayList, token)
                .then((res) => {
                  setPostData({
                    ...postData,
                    xlsx: null,
                  });
                  setPostDataError({});
                  alert(res.data.msg);
                  getHolidays();
                })
                .catch((err) => {
                  alert(err.response.data.msg)
                })
                :
                alert('File contain duplicate dates')
              }
            } else {
              alert("Please verify your XLSX file")
            }
          };
          if (rABS) {
            reader.readAsBinaryString(postData.xlsx);
          }
        }
        catch(err){
          console.log('file error:',err);
        }
      }
    } else {
      if (checkValid(postData)) {
        if (updateId === "") {
          BatchManagementServices.addHoliday({...postData}, token)
            .then((res) => {
              setPostData({
                ...postData,
                date: "",
                event: ""
              });
              alert(res.data.msg);
              getHolidays();
            })
            .catch((err) => {
              alert(err.response.data.msg)
            });
        } 
        else {
          BatchManagementServices.updateHoliday(updateId, postData, token)
            .then((res) => {
              if (res.data) {
                setPostData({
                  date: "",
                  event: "",
                });
                setUpdateId("");
                getHolidays();
                alert(res.data.msg);
              }
            })
            .catch((err) => {
              alert(err.response.data.msg)
            });
        }
      }
    }
  };

  return (
    <>
      <div className="container mb-5 w-75">
        {token && ["Admin"].includes(jwt_decode(token).role) && (
          <div className="container">
            <h3 className="text-center">Manage Holiday List</h3>
            <div className="d-flex row">
                <div className="col-12 col-lg-6">
                    <form onSubmit={handleAddHoliday}>
                      <div>
                        <label htmlFor="cname" className="form-label me-3">
                          Please Select Holiday insert Type :
                        </label>
                        <div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="insert-type"
                              checked={isExcel === false}
                              value="single-holiday"
                              onClick={()=>setIsExcel(false)}
                            />
                            <label className="form-check-label">
                              Add Holiday
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="insert-type"
                              checked={isExcel === true}
                              value="excel"
                              onClick={() => {
                                setIsExcel(true)
                              }}
                            />
                            <label className="form-check-label">
                              Uploading from an XLSX
                            </label>
                          </div>

                        </div>
                      </div>
                      { !isExcel ?
                        <div class="form-group">
                            <div className="mt-3">
                                <label>Holiday Date</label>
                                <input
                                    value={postData.date}
                                    onChange={handleChange}
                                    type="date"
                                    name="date"
                                    class="form-control mt-1"
                                />
                                {postDataError && postDataError.date?.length > 0 && (
                                    <small className="text-danger mt-1">
                                    {postDataError.date}
                                    </small>
                                )}
                            </div>
                            <div className="mt-3">
                                <label>Event</label>
                                <input
                                    value={postData.event}
                                    onChange={handleChange}
                                    type="text"
                                    name="event"
                                    class="form-control mt-1"
                                    placeholder="Event"
                                />
                                {postDataError && postDataError.event?.length > 0 && (
                                    <small className="text-danger mt-1">
                                    {postDataError.event}
                                    </small>
                                )}
                            </div>
                        </div>
                      :
                        <div className="my-3">
                          <label htmlFor="xlsx" className="form-label">
                            Select Xlsx file
                          </label>
                          <input
                            type="file"
                            name="xlsx"
                            id="xlsx"
                            value={postData?.xlsx ? postData?.xlsx.value : "" }
                            className="form-control"
                            required={isExcel}
                            onChange={handleChange}
                          />
                          {postDataError && postDataError.xlsx?.length > 0 && (
                              <small className="text-danger mt-1">
                              {postDataError.xlsx}
                              </small>
                          )}
                        </div>
                      }
                    <div className="mt-3 ">
                        { 
                          !updateId || isExcel?
                          <button className="btn btn-primary me-2">Submit</button>
                          :
                          <button className="btn btn-warning text-white me-2">Update</button>
                        }
                        <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                            setPostData({
                            date: "",
                            event: "",
                            xlsx: null,
                            });
                            setPostDataError({});
                            setUpdateId("");
                        }}
                        >
                        Reset
                        </button>
                    </div>
                    </form>
                </div>
                <div className="col-12 col-lg-6">
                    <div class="my-4 ms-5 overflow-y-auto" style={{height:"70vh"}}>
                    { holidayList.length ? (
                        <table className="table">
                            <thead className="fw-bold">
                            <th scope="col">#</th>
                            <th scope="col" className="col-md-3 ps-2">
                                Date
                            </th>
                            <th scope="col" className="ps-2">
                                Event
                            </th>
                            {["Admin"].includes(token && jwt_decode(token).role) && (
                                <th scope="col" className="ps-2">
                                Action
                                </th>
                            )}
                            </thead>
                            <tbody>
                                {holidayList.map((holiday, index) => {
                                return (
                                    <tr>
                                    <td>{index + 1}</td>
                                    <td>{DateFormat(holiday.date)}</td>
                                    {holiday?.event ? (
                                        <td>{holiday.event}</td>
                                    ) : (
                                        <td>-</td>
                                    )}
                                    {token && ["Admin"].includes(jwt_decode(token).role) && (
                                        <td>
                                        <button
                                            class="btn btn-warning px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                            onClick={() => {
                                            setPostData({
                                                date: DateFormat(holiday.date),
                                                event: holiday.event
                                            });
                                            setIsExcel(false);
                                            setUpdateId(holiday._id);
                                            }}
                                        >
                                            <FaPen className="color-white text-white" />
                                        </button>
                                        <button
                                            class="btn btn-secondary px-2 py-1 mx-1"
                                            onClick={() => {
                                            setOpenModal(!openModal);
                                            setUpdateId(holiday._id);
                                            }}
                                        >
                                            <FaTimes className="color-white text-white" />
                                        </button>
                                        </td>
                                    )}
                                    </tr>
                                );
                                })}
                            </tbody>
                        </table>
                    ) : (
                    <p className="text-center fs-4">Holiday List is Empty</p>
                    )}
                    </div>
                </div>
            </div>
          </div>
        )}
        <div>
          <Modal
            show={openModal}
            onHide={closeModal}
            style={{ marginTop: "5%" }}
            aria-labelledby="ModalHeader"
          >
            <Modal.Header>
              <Modal.Title>Remove Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure remove holiday ?</Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleRemoveHoliday}>
                Yes
              </button>
              <button className="btn btn-primary" onClick={closeModal}>
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}