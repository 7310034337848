import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageList from "../../components/images/ImageList";
import CourseService from "../../services/CourseService";
import TestCaseService from "../../services/TestCaseService";
import url from "../../servicesvariable";
import passAssessmentSVG from '../../assets/images/pass-assessment.svg'
import failAssessmentSVG from '../../assets/images/fail-assessment.svg'
import videoPlayerSVG from '../../assets/images/video_player_icon.svg'
import './ExamVideo.scss'

export default function ExamVideo() {
  let { batchName, skillName, examCode, hostId, userId } = useParams();
  const awsRootPath = url.examVideo.replace('trainees', '');
  const [questions, setQuestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [questionNumber, setQuestionNumber] = useState("Q001");
  const [examType, setExamType] = useState("");
  const [examSkill, setExamSkill] = useState("");
  const [videoType, setVideoType] = useState("");
  const [msg, setMsg] = useState("");
  const [passedCaseImage, setPassedCaseImage] = useState([])
  const [failedCaseImage, setFailedCaseImage] = useState([])
  const navigate = useNavigate();
  let count =0;
  /*
  * testResult Schema
  * {
  *   Q001: {passed: [], failed: [], error: []},
  *   Q002: {passed: [], failed: [], error: []}
  * }
  */
  const [testResult, setTestResult] = useState([])
  const [studentOverallResult, setStudentOverallResult] = useState({
    noOfPassedTestCase: 0,
    noOfFailedTestCase: 0,
    overallPercentage: 0,
  })
  const [browserHistory, setBrowserHistory] = useState({
    chrome: [
      {
        title: 'instagram - Google Search',
        url: 'https://www.google.com/search?q=instagram&rlz=1C1GCEU_en-GBIN1103IN1103&oq=instagram&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDINCAEQABiDARixAxiABDIGCAIQRRhAMg0IAxAAGIMBGLEDGIAEMgoIBBAAGLEDGIAEMgoIBRAAGLEDGIAEMgoIBhAAGLEDGIAEMg0IBxAAGIMBGLEDGIAE0gEIMTYxNGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8',
        last_visited: '2024-11-14T10:22:40.987+05:30'
      },
      {
        title: 'YouTube',
        url: 'https://www.youtube.com/',
        last_visited: '2024-11-14T10:22:29.646+05:30'
      },
      {
        title: 'Login',
        url: 'http://192.168.100.109/',
        last_visited: '2024-11-14T10:22:25.294+05:30'
      },
      {
        title: 'Login',
        url: 'http://192.168.100.109/Login?returnUrl=%252f',
        last_visited: '2024-11-14T10:22:25.294+05:30'
      },
      {
        title: 'SonicWall Network Security Login',
        url: 'https://192.168.100.1/sonicui/7/login/#/confirm',
        last_visited: '2024-11-14T10:15:14.048+05:30'
      },
      {
        title: 'SonicWall Network Security Login',
        url: 'https://192.168.100.1/sonicui/7/login/#/',
        last_visited: '2024-11-14T10:15:05.595+05:30'
      },
      {
        title: 'HTML a tag',
        url: 'https://www.w3schools.com/tags/tag_a.asp',
        last_visited: '2024-11-14T17:58:58.638+05:30'
      },
      {
        title: 'a tag - Google Search',
        url: 'https://www.google.com/search?q=a+tag&rlz=1C1GCEU_en-GBIN1103IN1103&oq=a+tag&gs_lcrp=EgZjaHJvbWUyEQgAEEUYORhDGLEDGIAEGIoFMgwIARAAGEMYgAQYigUyDAgCEAAYQxiABBiKBTIHCAMQABiABDIHCAQQABiABDIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPNIBCDMzMTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8',
        last_visited: '2024-11-14T17:58:57.441+05:30'
      },
      {
        title: 'Date - JavaScript | MDN',
        url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date',
        last_visited: '2024-11-14T17:57:35.669+05:30'
      },
      {
        title: 'date function in javascript - Google Search',
        url: 'https://www.google.com/search?q=date+function+in+javascript&sca_esv=fdc9a68c17428299&rlz=1C1GCEU_en-GBIN1103IN1103&ei=2c81Z6XyNOS9vr0P7KT0uQQ&oq=date+functio+&gs_lp=Egxnd3Mtd2l6LXNlcnAiDWRhdGUgZnVuY3RpbyAqAggCMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNMgcQABiABBgNSPoxUI4OWLkdcAF4AZABAJgBmwGgAY8NqgEEMC4xM7gBA8gBAPgBAZgCDqACvQ3CAgoQABiwAxjWBBhHwgILEAAYgAQYkQIYigXCAgsQABiABBixAxiDAcICBRAAGIAEwgIOEAAYgAQYsQMYgwEYigXCAggQABiABBixA8ICChAAGIAEGEMYigXCAg0QLhiABBhDGNQCGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAuGIAEGLEDGIMBwgIREAAYgAQYkQIYsQMYgwEYigXCAg4QLhiABBjHARiOBRivAZgDAIgGAZAGCJIHBDEuMTOgB89e&sclient=gws-wiz-serp',
        last_visited: '2024-11-14T17:57:31.492+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_now2',
        last_visited: '2024-11-14T17:57:12.957+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_now',
        last_visited: '2024-11-14T17:57:10.268+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_gettime',
        last_visited: '2024-11-14T17:57:02.784+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_gettime2',
        last_visited: '2024-11-14T17:57:00.488+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_gettime3',
        last_visited: '2024-11-14T17:56:54.889+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_weekday2',
        last_visited: '2024-11-14T17:56:48.696+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_getday2',
        last_visited: '2024-11-14T17:56:42.922+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_getminutes',
        last_visited: '2024-11-14T17:56:33.245+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_getminutes2',
        last_visited: '2024-11-14T17:56:28.632+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_gethours',
        last_visited: '2024-11-14T17:55:08.208+05:30'
      },
      {
        title: 'W3Schools Tryit Editor',
        url: 'https://www.w3schools.com/js/tryit.asp?filename=tryjs_date_gethours2',
        last_visited: '2024-11-14T17:54:59.360+05:30'
      },
      {
        title: 'JavaScript Date Methods',
        url: 'https://www.w3schools.com/js/js_date_methods.asp',
        last_visited: '2024-11-14T17:54:23.734+05:30'
      }
    ],
    edge: [
      {
        title: 'Sign in · GitLab',
        url: 'https://rxgit.radixweb.in/rxprojects/trainees/dotnet/3047-Mihir.M-24-ZeroThreat/-/tree/master/',
        last_visited: '2024-11-14T10:22:48.847+05:30'
      },
      {
        title: 'Sign in · GitLab',
        url: 'https://rxgit.radixweb.in/users/sign_in',
        last_visited: '2024-11-14T10:22:48.847+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/ExamVideo/March-24-ZeroThreat/VueJS/VueJS007/mihirm.maheta_3047/6605091005569a48e749f981',
        last_visited: '2024-11-14T10:18:19.916+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/batchinfo/VueJS/internalExam/65fd2fb205569a48e749f880?startDate=2024-06-07&endDate=2024-06-12',
        last_visited: '2024-11-14T10:18:12.966+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/calendar',
        last_visited: '2024-11-14T10:18:04.402+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/admin/dashboard',
        last_visited: '2024-11-14T10:18:00.404+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/login',
        last_visited: '2024-11-14T10:17:47.138+05:30'
      },
      {
        title: 'Radixweb Tutorials',
        url: 'http://localhost:3000/',
        last_visited: '2024-11-14T10:17:46.768+05:30'
      },
      {
        title: 'SonicWall Network Security Login',
        url: 'https://192.168.100.1/sonicui/7/login/#/confirm',
        last_visited: '2024-11-14T10:15:14.048+05:30'
      },
      {
        title: 'SonicWall Network Security Login',
        url: 'https://192.168.100.1/sonicui/7/login/#/',
        last_visited: '2024-11-14T10:15:05.595+05:30'
      }
    ],
    firefox: [
      {
        title: 'GitHub · Build and ship software on a single, collaborative platform · GitHub',
        url: 'https://github.com/',
        last_visited: '2024-11-14T10:23:07.998+05:30'
      },
      {
        title: 'JavaScript Date Methods',
        url: 'https://www.w3schools.com/js/js_date_methods.asp',
        last_visited: '2024-11-14T10:23:04.104+05:30'
      }
    ]
  })

  const getAwsImageUrl = (relativeImageUrl) => {
    return awsRootPath + relativeImageUrl;
  }

  const DateFormat = (dateValue) => {
    const date = new Date(dateValue);
    const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`} ${
      monthsOfYear[date.getMonth()]
    } ${date.getFullYear()}`;
  };

  const TimeFormat = (dateValue) => {
    let date = new Date(dateValue);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  };

  const getTestCaseImage = async (token) => {

    const response = await TestCaseService.getTestCasesImages(batchName, skillName, examCode, hostId, questionNumber, token)

    if (response.status === 200) {

      const { failedTestCaseImg, passedTestCaseImg } = response.data.data;

      setFailedCaseImage(failedTestCaseImg.map(img => {
        return {
          ...img,
          src: getAwsImageUrl(img.imgUrl),
          alt: img.imgUrl,
          className: 'w-100 h-100 object-fit-cover'
        }
      }))

      setPassedCaseImage(passedTestCaseImg.map(img => {
        return {
          ...img,
          src: getAwsImageUrl(img.imgUrl),
          alt: img.imgUrl,
          className: 'w-100 h-100 object-fit-cover'
        }
      }))
    }
  }

  const mystyle = {
    fontSize: "0.9rem",
    fontWeight: "500",
    fontFamily: "Poppins, sans-serif",
  };
  const videoWidth = {
    width: "auto",
  };

  const handleDwonloadZip = () => {
    var src =
      url.examVideo + "/" +
      batchName +
      "/" +
      skillName +
      "/" +
      examCode +
      "/" +
      hostId +
      "/" +
      questionNumber +
      "/Source.zip";
    window.location.href = src;
  };

  const questionVideo =
    url.examVideo + "/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    "_" +
    questionNumber +
    videoType;
  const allQuestionVideo =
    url.examVideo + "/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    videoType;

  const getAbsolutePath = (queNumber) => {
    return `${url.examVideo}/${batchName}/${skillName}/${examCode}/${hostId}/${queNumber}/results/`;
  }

  const getTextColorBasedOnPercentage = (percentage) => {

    if (percentage >= 90) {
      return 'text-success'
    }

    if (percentage >= 80) {
      return 'text-primary'
    }

    if (percentage >= 65) {
      return 'text-warning'
    }

    if (percentage >= 50) {
      return 'text-info'
    }

    return 'text-danger'
  }

  useEffect(() => {

    // reset the Student Overall Result
    setStudentOverallResult({
      ...studentOverallResult,
      noOfPassedTestCase: 0,
      noOfFailedTestCase: 0,
    })

    // calculate the Number of PassedTestCase Number of FailedTestCase
    let overallPercentage = 0;
    for (let key in testResult) {

      setStudentOverallResult((prev) => ({
        ...prev,
        noOfPassedTestCase: (testResult[key]?.passCases.length ?? 0) + prev.noOfPassedTestCase,
        noOfFailedTestCase: (testResult[key]?.failedCases.length ?? 0) + prev.noOfFailedTestCase,
      }))

      if ((testResult[key].failedCases.length + testResult[key].passCases.length) !== 0) {
        overallPercentage += (testResult[key].passCases.length / (testResult[key].failedCases.length + testResult[key].passCases.length)) * 100;
      }
    }

    setStudentOverallResult((prev) => ({
      ...prev,
      overallPercentage: overallPercentage / questions.length
    }))


    setStudentOverallResult((prev) => ({
      ...prev,
      overallPercentage: isNaN(prev.overallPercentage) ? 0 : prev.overallPercentage.toFixed(2),
    }))

  }, [testResult])

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {

      CourseService.getSingleExamSchedule(examCode, userId, token)
        .then(async (res) => {
          if (res.data) {
            if (res.data.success) {
              // await handleTestCase(res.data.Questions) TODO REMOVE
              setQuestions(res.data.Questions);
              setUserName(res.data.Name);
              setExamType(res.data.typeExam);
              setExamSkill(res.data.ExamSkill);
              setVideoType(res.data.VideoType);
            } else {
              setMsg(res.data.msg);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setTestResult({});

      questions.forEach(async (question) => {

        let obj = {
          passCases: [],
          failedCases: [],
          error: []
        }

        await TestCaseService.getPassedTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {

            obj = {
              ...obj,
              passCases: res.data
            }
          })

        await TestCaseService.getFailedTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {
            obj = {
              ...obj,
              failedCases: res.data
            }
          })

        await TestCaseService.getErrorTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {
            obj = {
              ...obj,
              error: res.data
            }
          })

        setTestResult((prev) => {
          return {
            ...prev,
            [question]: obj
          }

        });
      })
    }
  }, [questions])

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      getTestCaseImage(token)
    }
  }, [questionNumber])

  return (
    <div className="container-fluid">
      {msg === "" ? (
        <>
          <section className="ExamVideo-module">
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <div>
                  <h1 className="text-center h3 me-3 mb-0">{examSkill} Questions</h1>
                </div>

                <p className={`mt-0 mb-0 ${getTextColorBasedOnPercentage(studentOverallResult.overallPercentage)}`}><b>Overall Percentage:</b> {studentOverallResult.overallPercentage}</p>
                <p className="mt-0 mb-0"><b>Passed Testcase:</b> {studentOverallResult.noOfPassedTestCase}</p>
                <p className="mt-0 mb-0"><b>Failed Testcase:</b> {studentOverallResult.noOfFailedTestCase}</p>
                <p className="text-dark me-2 mt-2">
                  <h3 className="h2">{userName}</h3>
                  <div className="fs-6">
                  </div>
                </p>
              </div>
              <hr className="mt-4 mb-5" />
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4">

                  <div class="list-group mb-4">
                    {questions &&
                      questions.length > 0 &&
                      questions.map((item, index) => {
                        return (
                          <>
                            <a
                              className={`list-group-item cursor-pointer
                              ${item === questionNumber ? "bg-blue text-white" : ""}`
                              }
                              onClick={(e) => setQuestionNumber(item)}
                            >
                              Question {index + 1}    <span className="text-end" style={{ paddingLeft: "50px" }} >
                                <a target="_blank" className="text-warning" style={{ paddingLeft: "20px", textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "error.json"}>
                                  {testResult?.[item]?.error.length || 0}

                                </a>
                                <a target="_blank" className="mx-2 text-danger" style={{ textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "failedCases.json"}>
                                  {testResult[item]?.failedCases.length || 0}
                                </a>
                                <a target="_blank" className="text-success" style={{ textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "passCases.json"}>
                                  {testResult[item]?.passCases.length || 0}
                                </a>
                              </span>
                            </a>


                          </>
                        );
                      })
                    }
                  </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-8">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#qa-content"><i class="fas fa-laptop-code me-2"></i>Question</a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-video">
                        <img src={videoPlayerSVG} alt='passAssessmentSVG' />
                        Video
                      </a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-passedCaseImage">
                        <img src={passAssessmentSVG} alt='passAssessmentSVG' />
                        Passed Images
                        ({testResult[questionNumber]?.passCases.length || 0})
                      </a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-failedCaseImage">
                        <img src={failAssessmentSVG} alt='passAssessmentSVG' />
                        Failed Images
                        ({testResult[questionNumber]?.failedCases.length || 0})
                      </a>
                    </li>
                    <li class="nav-item">
                      <button className="btn btn-primary bg-blue" onClick={handleDwonloadZip}>
                        Source Code
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                    {/* <li class="nav-item text-bg-warning">
                      <a class="nav-link text-dark" data-bs-toggle="tab" href="#qa-browserHistory">
                          <i class="fas fa-chrome me-2"></i>Browser History
                      </a>
                    </li> */}
                  </ul>
                  <div class="tab-content pt-3">
                    <div id="qa-content" class="tab-pane fade in active show">
                      {questionNumber !== "" && (
                        <>
                          <div class="embed-responsive embed-responsive-21by9">
                            <iframe
                              src={
                                url.examVideo + "/" +
                                batchName +
                                "/" +
                                skillName +
                                "/" +
                                examCode +
                                "/" +
                                hostId +
                                "/" +
                                questionNumber +
                                "/" +
                                questionNumber +
                                ".html"
                              }
                              width="100%"
                              height="600"
                              title="Source Code"
                            ></iframe>
                          </div>
                        </>

                      )}
                    </div>
                    <div id="qa-video" class="tab-pane fade">
                      <div className="embed-responsive embed-responsive-16by9">
                        <video
                          id="video"
                          className="w-100"
                          src={questionNumber !== "" ? questionVideo : allQuestionVideo}
                          height="600"
                          controls
                        ></video>
                      </div>
                    </div>
                    <div id="qa-passedCaseImage" class="tab-pane fade">
                      <ImageList images={passedCaseImage} heading={'Passed TestCase Images'} />
                    </div>
                    <div id="qa-failedCaseImage" class="tab-pane fade">
                      <ImageList images={failedCaseImage} heading={'Failed TestCase Images'} />
                    </div>
                    <div id="qa-browserHistory" className="tab-pane fade">
                      <table className="table mt-4 w-100">
                        <thead className="fw-bold">
                          <tr className="w-100">
                            <th width="5%" align="center" className="text-center">#</th>
                            <th width="10%" align="center" className="text-center">Time</th>
                            <th width="70%">URL</th>
                            <th width="15%" align="center" className="text-center">Browser</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            Object.keys(browserHistory).length && 
                            Object.keys(browserHistory).map(browser => {
                              return browserHistory[browser].map((history,index)=>{
                                count += 1
                                return(
                                  <tr key={count}>
                                    <td width="5%" align="center">{count}</td>
                                    <td width="20%" align="center">{DateFormat(history.last_visited) + ' ' + TimeFormat(history.last_visited)}</td>
                                    <td width="65%" style={{wordBreak: "break-all"}}>
                                      <a href={history.url} target="_blank">{history.title}</a>
                                      </td>
                                    <td width="10%" align="center">{browser}</td>
                                  </tr>
                                )
                              })
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div class="text-danger text-center pt-3 fs-3">{msg}</div>
      )}
    </div>
  );
}
