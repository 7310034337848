import React, { useState, useEffect, useContext } from "react";
import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaLaptopCode, FaUserFriends, FaPenNib } from "react-icons/fa";
import { AiFillSetting, AiOutlineSolution } from "react-icons/ai";
import { UserContext } from "../../routing/Routing";
import $ from "jquery";
import { adminRoutes } from "./routes";

const accordionMap = {
  user: "panelZero",
  course: "panelOne",
  "batch-management": "panelTwo",
  test: "panelThree",
  result: "panelFour",
};

const AccordionItem = ({ title, icon, targetId, children }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className="accordion-item">
      <h6 className="accordion-header">
        <button
          type="button"
          className="accordion-button position-relative"
          data-bs-toggle="collapse"
          data-bs-target={`#${targetId}`}
          aria-expanded={isExpanded}
          aria-controls={targetId}
          onClick={handleToggle}
        >
          {icon} {title}
        </button>
      </h6>
      <div
        className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
        id={targetId}
      >
        <div className="accordion-body p-0 bg-lblue">{children}</div>
      </div>
    </div>
  );
};

const Admin = () => {
  const [selected, setSelected] = useState({ ch: "", op: "" });
  const { view } = useContext(UserContext);
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");
  const decoded = token && jwt_decode(token);
  const role = decoded?.role;

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (role === "Student") {
      window.location.href = "/";
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const choice = pathname?.split("/admin/")[1]?.split("/")[0];
    if (accordionMap[choice]) {
      $(`#${accordionMap[choice]}`).addClass("show");
    }

    LoginService.getUser(token)
      .then((res) => {
        if (!res.data.msg) {
          navigate("/");
        } else {
          if (view) {
            handleChoice("user", "subscription");
            setSelected({ ch: "user", op: "subscription" });
          } else {
            if (pathname === "/admin" || pathname === "/admin/") {
              handleChoice("dashboard", "");
              setSelected({ ch: "dashboard", op: "" });
            } else {
              const choice = pathname?.split("/admin/")[1]?.split("/");
              if (choice) {
                if (choice.length === 1) {
                  handleChoice(choice[0], "");
                  setSelected({ ch: choice[0], op: "" });
                } else if (choice.length === 2) {
                  handleChoice(choice[0], choice[1]);
                  setSelected({ ch: choice[0], op: choice[1] });
                } else {
                  setSelected({ ch: choice[0], op: choice[1] });
                }
              }
            }
          }
        }
      })
      .catch((ex) => console.log(ex));
    LoginService.getTrainers(token).then((res) => {
      if (res.data.length > 0) {
        var reqs = 0;
        for (var i = 0; i < res.data.length; i++) {
          var user = res.data[i];
          if (user.subscription.length !== 0) {
            for (var j = 0; j < user.subscription.length; j++) {
              if (!user.subscription[j].Subscribe) {
                reqs += 1;
              }
            }
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const handleChoice = (choice, operation) => {
    switch (choice) {
      case "course":
        if (operation === "update-training-plan-request") {
          navigate("/admin/course/update-training-plan-request");
        }
        break;
      default:
    }
  };

  const LinkWrapper = ({ navigation, parentPath, childPath, children }) => {
    const handleClick = (parentPath, childPath) => {
      handleChoice(parentPath, childPath);
      setSelected({ ch: parentPath, op: childPath });
    };

    return (
      <Link
        className={"text-white text-decoration-none accordion-item w-100 "}
        to={navigation}
        onClick={() => handleClick(parentPath, childPath)}
      >
        <button
          className={
            selected.ch === parentPath && selected.op === childPath
              ? "accordion-item w-100 ps-4 py-2 text-start bg-green"
              : "accordion-item w-100 ps-4 py-2 text-start"
          }
        >
          {children}
        </button>
      </Link>
    );
  };

  return (
    <div className="row dashboard g-0">
      <div className="col-lg-2 leftbar">
        <div className="accordion mt-3">
          {
            role === "Admin" &&
          <LinkWrapper
            navigation={"/admin/dashboard"}
            childPath={""}
            parentPath={"dashboard"}
            children={
              <>
                <FaHome className="me-2 fs-5" /> Dashboard
              </>
            }
          />
          }

          <AccordionItem
            title="User"
            icon={<FaUserFriends className="me-2 fs-5 " />}
            targetId="panelZero"
          >
            {adminRoutes.user.map((item, index) => {
              if (["HR", "Trainer"].includes(role)) {
                if (item.childPath === 'manage') {
                  return <LinkWrapper {...item} key={index} />
                }
              }
              else {
                return <LinkWrapper {...item} key={index} />
              }
            })}
          </AccordionItem>
          {
            role == 'Admin' &&
            <AccordionItem
              title="Courses"
              icon={<FaLaptopCode className="me-2 fs-5" />}
              targetId="panelOne"
            >
              {adminRoutes.course.map(
                (item) => item && <LinkWrapper {...item} key={item.id} />
              )}

              {localStorage.getItem("token") &&
                jwt_decode(localStorage.getItem("token")).role === "Trainer" && (
                  <button
                    className={`accordion-item w-100 ps-4 py-2 text-start ${selected.ch === "course" &&
                        selected.op === "update-training-plan-request"
                        ? "bg-green"
                        : ""
                      }`}
                    onClick={() => {
                      handleChoice("course", "update-training-plan-request");
                      setSelected({
                        ch: "course",
                        op: "update-training-plan-request",
                      });
                    }}
                  >
                    <AiFillSetting className="me-2 fs-5" /> Update Training Plan
                    Request
                  </button>
                )}
            </AccordionItem>
          }
          <AccordionItem
            title="Batch Management"
            icon={<FaLaptopCode className="me-2 fs-5" />}
            targetId="panelTwo"
          >
            {adminRoutes["batch-management"].map((item) => {
              if (["HR", "Trainer"].includes(role)) {
                if (!['manage-holiday-list', 'add-day'].includes(item.childPath)) {
                  return <LinkWrapper {...item} key={item.id} />   
                }
              }
              else{
                return <LinkWrapper {...item} key={item.id} />
              }
            }
            )}
          </AccordionItem>
          <AccordionItem
            title="Test Module"
            icon={<FaPenNib className="me-2 fs-5" />}
            targetId="panelThree"
          >
            {adminRoutes.test.map((item) => {
              if (["HR", "Trainer"].includes(role)) {
                if (item.childPath === 'create-exam') {
                  return <LinkWrapper {...item} key={item.id} />
                }
              }
              else {
                return <LinkWrapper {...item} key={item.id} />
              }
              <LinkWrapper {...item} key={item.id} />
            })}
          </AccordionItem>
          {
            role === "Admin" &&
          <AccordionItem
            title="Result Module"
            icon={<AiOutlineSolution className="me-2 fs-5" />}
            targetId="panelFour"
          >
            {adminRoutes.result.map((item) => (
              <LinkWrapper {...item} key={item.id} />
            ))}
          </AccordionItem>
          }
        </div>
      </div>
      <div className="col-lg-10 rightbar pt-3">
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
