import React, { useEffect, useState } from "react";
import BatchService from "../../services/BatchManagementServices";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import LoginService from "../../services/LoginService";
import Select from "react-select";
import {
  FaCheck,
  FaPen,
  FaTimes,
  FaSortAlphaUp,
  FaSortAlphaDown,
} from "react-icons/fa";
import ToolTip from "../BsComponents/ToolTip";
import Pagination from "react-js-pagination";
import Loader from "../../components/loader/Loader";
// import ActiveInactiveConfirmationBox from '../confirmationbox/ActiveInactiveConfirmation'
import { format } from "../../utils/Date/format";

const UserListing = () => {
  const Options = [
    {
      label: "approved",
      name: "approved",
      value: true,
    },
    {
      label: "disapproved",
      name: "approved",
      value: false,
    },
    {
      label: "Admin",
      name: "role",
      value: "Admin",
    },
    {
      label: "Student",
      name: "role",
      value: "Student",
    },
    {
      label: "Trainer",
      name: "role",
      value: "Trainer",
    },
    {
      label: "active",
      name: "deactivate",
      value: false,
    },
    {
      label: "InActive",
      name: "deactivate",
      value: true,
    },
    {
      label: "@gmail.com",
      name: "email",
      value: "gmail",
    },
    {
      label: "@radixweb.com",
      name: "email",
      value: "radixweb",
    },
  ];
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [batches, setBatches] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [message, setMessage] = useState({ status: true, msg: "" });
  const [loader, setLoader] = useState(false);
  const [usersLength, setUsersLength] = useState(0);
  const [isAscending, setIsAscending] = useState(true);
  const [leaveReason, setLeaveReason] = useState([]);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    BatchService.GetAllBatch(token)
      .then((res) => {
        setBatches(res.data.data);
      })
      .catch((ex) => console.log(ex));
    handleSubmitFilterData(currentPage, filterData);
  }, []);

  const handleMessage = (message, status, magtime = false) => {
    setConfirmBox(true);
    setMessage({ msg: message, status: status });
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  const handleCatchError = (err) => {
    setLoader(false);
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (
          err["response"]?.data?.message ===
          "You are not authorized to view this content"
        ) {
          handleMessage(err["response"].data.message, false);
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false);
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };

  const setDynemicFilterObject = (selectedOptions) => {
    const filterObject = {};
    if (selectedOptions?.length > 0) {
      selectedOptions.forEach((data) => {
        if (data.name !== "email") {
          filterObject[data.name] = filterObject[data.name]
            ? { value: filterObject[data.name]["value"].concat([data.value]) }
            : { value: [data.value] };
        } else {
          const handleMergeFilterObject = (oldValue, newValue) => {
            const oldValueSplit = oldValue["$regex"].split(".")[0];
            const oldValueConvertArray = JSON.parse(oldValueSplit);
            const mergerOldAndNewValue = `["${oldValueConvertArray
              .concat(newValue)
              .join(" ")}"].com`;
            return { value: { $regex: mergerOldAndNewValue } };
          };
          filterObject[data.name] = filterObject[data.name]
            ? handleMergeFilterObject(filterObject[data.name].value, data.value)
            : { value: { $regex: `["${data.value}"].com` } };
        }
      });
    }
    return filterObject;
  };

  const handleChange = (selectedOptions, e) => {
    setFilterOptions(selectedOptions);
    handleSubmitFilterData(1, {
      ...filterData,
      ...setDynemicFilterObject(selectedOptions),
    });
  };

  const handleSubmitFilterData = (pageNumber, filterObject) => {
    setLoader(true);
    setTimeout(() => {
      LoginService.getUsersPaginate(pageNumber, filterObject, token)
        .then((res) => {
          if (res.data) {
            setLoader(false);
            setLeaveReason(res.data.trainingLeaveReasons);
            setFilteredUsers(res.data.filterUsers);
            setCurrentPage(parseInt(pageNumber));
            setUsersLength(res.data.totalUsers);
          }
        })
        .catch((error) => {
          handleCatchError(error);
        });
    }, 500);
    // setTimeout(() => handleCall(pageNumber,filterObject), 3000);
  };

  const findUserBatch = (userId) => {
    const data = batches.find((item) => {
      if (item.users.find((u) => u._id === userId)) {
        return item.name;
      }
    });
    if (data) {
      return data.name;
    }
  };

  const handleDeactivateUser = (e, item) => {
    // const { trainingLeaveReason, remarks } = item;
    // Check if Reason is empty
    // deactivation logic and API call
    item.deactivate = !e.target.checked;
    // if (item.deactivate) {
    //   if (!trainingLeaveReason) {
    //     setMessage({ msg: "Reason is required", status: false });
    //     return;
    //   }
    //   // Check if Remarks is empty
    //   if (!remarks) {
    //     setMessage({ msg: "Remarks are required", status: false });
    //     return;
    //   }
    // }
    LoginService.putUser(item, token)
      .then((res) => {
        if (res.data) {
          handleMessage(res.data.message, true, true);
          handleSubmitFilterData(1, {
            ...filterData,
            ...setDynemicFilterObject(filterOptions),
          });
        }
      })
      .catch((error) => {
        handleCatchError(error);
      });
  };

  const handleApproveUser = (item, status) => {
    setLoader(true);
    LoginService.updateApprovalRequest(item._id, status, token)
      .then((res) => {
        if (res.data) {
          handleSubmitFilterData(1, {
            ...filterData,
            ...setDynemicFilterObject(filterOptions),
          });
          handleMessage(res.data.message, true, true);
        }
      })
      .catch((error) => {
        handleCatchError(error);
      });
    setLoader(false);
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  const handlePageChange = (e) => {
    setCurrentPage(e);
    handleSubmitFilterData(e, {
      ...filterData,
      ...setDynemicFilterObject(filterOptions),
    });
  };

  const handleSorting = () => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (isAscending) {
        return a.username.localeCompare(b.username);
      } else {
        return b.username.localeCompare(a.username);
      }
    });
    setFilteredUsers(sortedUsers);
    setIsAscending(!isAscending);
  };

  const handleSelectedLeaveReason = (selectedValue, index, fieldName) => {
    setFilteredUsers((users) => {
      const copyUsers = [...users];
      copyUsers[index][fieldName] = selectedValue;
      return copyUsers;
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSubmitFilterData(1, {
        ...filterData,
        ...setDynemicFilterObject(filterOptions),
      });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [filterData]);

  const handleSearchBox = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFilterData({
        ...filterData,
        [name]: {
          label: value,
          value: { $regex: value, $options: "i" },
        },
      });
    } else {
      setFilterData((filterData) => {
        const copyFilterData = { ...filterData };
        delete copyFilterData[name];
        return copyFilterData;
      });
    }
  };

  const handleCreateUpadateDate = (date) => {
    return format(date)
  };
  return (
    <>
      {loader && <Loader loaderStatus={loader} message="Loading ....." />}
      {confirmBox && <handleMessage message={message} />}
      <h4>All Users</h4>
      <div className={"row my-4"}>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <input
            className="form-control"
            type="search"
            name="username"
            value={filterData?.username?.label || ""}
            placeholder="Search by Name"
            onChange={(e) => handleSearchBox(e)}
          />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <input
            className="form-control"
            type="search"
            name="hostId"
            placeholder="Search by HostID"
            value={filterData?.hostId?.label || ""}
            onChange={(e) => {
              handleSearchBox(e);
            }}
          />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <Select
            placeholder="Apply Filters ..."
            isMulti
            options={Options}
            className="basic-multi-select"
            classNamePrefix="select"
            isSearchable
            onChange={(selectedOptions, e) => {
              handleChange(selectedOptions, e);
            }}
            value={filterOptions}
          />
        </div>
      </div>
      <hr className={"py-0 my-0"} />

      <table className="table" style={{ marginBottom: "0px" }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">
              Name
              <span
                className="ms-5"
                style={{ cursor: "pointer" }}
                onClick={handleSorting}
              >
                {isAscending ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
              </span>
            </th>
            <th scope="col">Email</th>
            <th scope="col">HostId</th>
            <th scope="col">Batch Name</th>
            <th scope="col">Role</th>
            <th>Reason To Deactivate</th>
            <th>Remarks for Deactivate</th>
            <th>Create By</th>
            <th>Updated By</th>
            {/* <th>User Deactivate</th> */}
            {["Admin", "HR", "Trainer"].includes(jwt_decode(localStorage.getItem("token")).role) && (
              <th scope="col" style={{ paddingLeft: "50px" }}>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 &&
            filteredUsers.map((user, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{(currentPage - 1) * 7 + index + 1}</th>
                  <td
                    style={{
                      wordBreak: "break-all",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    {user.username}
                  </td>
                  <td
                    style={{
                      wordBreak: "break-all",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    {user.email}
                  </td>
                  <td>{user.hostId ? user.hostId : "-"}</td>
                  <td>{findUserBatch(user._id) || "-"}</td>
                  <td>{`${user.role} ${user.role === "Student" ? "👨‍🎓" : "👩‍🏫"
                    } `}</td>

                  <td style={{ whiteSpace: "nowrap" }}>
                    <div
                      className="d-inline-block"
                      style={{ verticalAlign: "bottom" }}
                    >
                      <select
                        className="form-select w-100"
                        onChange={(e) =>
                          handleSelectedLeaveReason(
                            e.target.value,
                            index,
                            "trainingLeaveReason"
                          )
                        }
                        value={user.trainingLeaveReason || ""}
                      >
                        <option value="">Select a valid Reason</option>
                        {leaveReason.map((leaveReason, key) => (
                          <option key={key} value={leaveReason}>
                            {leaveReason}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>

                  <td>
                    <input
                      type="text"
                      value={user.remarks || ""}
                      onChange={(e) =>
                        handleSelectedLeaveReason(
                          e.target.value,
                          index,
                          "remarks"
                        )
                      }
                      placeholder="Remarks"
                    />
                  </td>
                  {user.createdBy && user.createdBy.username !== "" ? (
                    <td>{user.createdBy['username']} on {handleCreateUpadateDate(user.createdAt)}</td>) : <td></td>}
                  {user.updatedBy && user.updatedBy.username !== "" ? (
                    <td>{user.updatedBy['username']} on {handleCreateUpadateDate(user.updatedAt)}</td>) : <td></td>}
                  {/* Action */}
                  {jwt_decode(localStorage.getItem("token")).role ===
                    "Admin" && (
                      <td>
                        <div className="d-flex justify-content-center mx-2">
                          <div className="form-check form-switch">
                            {!user.deactivate ? (
                              <ToolTip title="Inactive User">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="disableSwitch"
                                  checked={!user.deactivate}
                                  // disabled={
                                  //   !user.deactivate
                                  //     ? user.trainingLeaveReason && user.remarks
                                  //       ? false
                                  //       : true
                                  //     : false
                                  // }
                                  onChange={(e) => handleDeactivateUser(e, user)}
                                />
                              </ToolTip>
                            ) : (
                              <ToolTip title="Active User">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="disableSwitch"
                                  checked={!user.deactivate}
                                  // disabled={
                                  //   !user.deactivate
                                  //     ? user.trainingLeaveReason && user.remarks
                                  //       ? false
                                  //       : true
                                  //     : false
                                  // }
                                  onChange={(e) => handleDeactivateUser(e, user)}
                                />
                              </ToolTip>
                            )}
                          </div>
                          <div>
                            {user.approved != true && (
                              <ToolTip title="Approve Request">
                                <button
                                  className="btn btn-secondary px-2 py-1 mx-1 text-white"
                                  onClick={() => {
                                    handleApproveUser(user, true);
                                  }}
                                >
                                  <FaTimes className="color-white text-white" />
                                </button>
                              </ToolTip>
                            )}
                            {user.approved != false && (
                              <ToolTip title="Reject Request">
                                <button
                                  className="btn btn-success px-2 py-1 mx-1"
                                  onClick={() => {
                                    handleApproveUser(user, false);
                                  }}
                                >
                                  <FaCheck className="color-success text-white" />
                                </button>
                              </ToolTip>
                            )}
                          </div>
                          <div>
                            <ToolTip title="Edit User">
                              <button
                                className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                onClick={() => {
                                  navigate(
                                    `/admin/user/manage/update/${user._id}`,
                                    { state: user }
                                  );
                                }}
                              >
                                <FaPen className="color-white text-white" />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      </td>
                    )}
                    {
                      ["HR", "Trainer"].includes(jwt_decode(localStorage.getItem("token")).role)
                      &&
                      (<td>
                        <div className="d-flex justify-content-center mx-2">
                        <div>
                            <ToolTip title="Edit User">
                              <button
                                className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                onClick={() => {
                                  navigate(
                                    `/admin/user/manage/update/${user._id}`,
                                    { state: user }
                                  );
                                }}
                              >
                                <FaPen className="color-white text-white" />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      </td>)
                    }


                </tr>
              );
            })}
        </tbody>
      </table>

      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "8px" }}
      >
        <Pagination
          activePage={currentPage}
          itemClass="page-item"
          linkClass="page-link"
          itemsCountPerPage={7}
          totalItemsCount={usersLength}
          pageRangeDisplayed={10}
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </>
  );
};

export default UserListing;
